import { PayerSessionSummary } from "../components/PayerSessionSummary";

export default function ReadyCheck({
  payerSessions,
  subtotalPlusTax,
  tipPercent,
  partyMemberCount,
  myPayerSessionID,
  otherPayerTotal,
}) {
  return (
    <div className="flex flex-col">
      <div>
        <p className="text-white text-xl p-4">Make sure everyone is ready</p>
        <PayerSessionSummary
          payerSessions={payerSessions}
          subtotalPlusTax={subtotalPlusTax}
          tipPercent={tipPercent}
          otherPayerTotal={otherPayerTotal}
          myPayerSessionID={myPayerSessionID}
        />
        <div className="pt-4 text-white text-xl ">
          {" "}
          <b>
            {payerSessions.filter((el) => el.ready).length} /{" "}
            {partyMemberCount - 1}{" "}
          </b>
          party people{" "}
        </div>
        <div className="text-white">are ready to send you their share</div>
      </div>
    </div>
  );
}
