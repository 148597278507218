import React, { useState, useEffect } from "react";
import Capture from "./routes/Capture";
import Confirmation from "./routes/Confirmation";
import Selection from "./routes/Selection";
import LoadingScreen from "./routes/LoadingScreen";
import db, { createFirestoreSession } from "./services/firestore";
import Modal from "react-modal";
import { trimPrice } from "./services/numberString";

import {
  query,
  collection,
  doc,
  getDoc,
  orderBy,
  getDocs,
} from "firebase/firestore";

import "./App.css";
import mixpanel from "mixpanel-browser";
import PartyCount from "./routes/PartyCount";
import UsernameInput from "./routes/UsernameInput";

Modal.setAppElement("#root");

function App() {
  const [amounts, setAmounts] = useState([]);
  const [total, setTotal] = useState(0.0);
  const [taxAmount, setTaxAmount] = useState(0.0);
  const [estimatedTaxRate, setEstimatedTaxRate] = useState(0.08);
  const [calculatedTotal, setCalculatedTotal] = useState(0.0);
  const [page, setPage] = useState(-1);
  const [receiptID, setReceiptID] = useState("");
  const [partyCount, setPartyCount] = useState(2);
  const [username, setUsername] = useState(
    localStorage.getItem("splitPartyPayUsername") ?? ""
  );

  useEffect(() => {
    if (username) {
      localStorage.setItem("splitPartyPayUsername", username);
    }
  }, [username]);

  const setStateFromDoc = async (docID) => {
    console.log(docID);
    const docRef = doc(db, "sessions", docID);
    const docSnap = await getDoc(docRef);
    let sessionData = docSnap.data();
    //  setStateFromDoc(sessionData);

    const q = query(collection(docRef, "items"), orderBy("index", "asc"));
    const querySnapshot = await getDocs(q);
    const pulledAmounts = [];
    let subtotal = 0;
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const price = docData.amount;
      subtotal += price;
      docData.data = price;
      pulledAmounts.push(docData);
    });
    console.log("subtotal: " + subtotal);
    if (sessionData.estimatedTaxRate) {
      setEstimatedTaxRate(sessionData.estimatedTaxRate);
    } else {
      setEstimatedTaxRate(0);
    }
    if (sessionData.ocrTax) {
      setTaxAmount(sessionData.ocrTax);
    }
    setTotal(sessionData.ocrTotal);
    setCalculatedTotal(subtotal);
    setAmounts(pulledAmounts);
    setPage(1);
    setReceiptID(docID);
  };

  useEffect(() => {
    console.log("TRIGGER");
    const params = new URLSearchParams(window.location.search);
    if (params.has("debugConfirmation")) {
      console.log("loading page " + params.get("debugConfirmation"));
      if (receiptID !== params.get("debugConfirmation")) {
        setStateFromDoc(params.get("debugConfirmation"));
      }
    } else if (
      params.get("receipt") &&
      sessionStorage.getItem("isPartyLeader") === "true"
    ) {
      if (receiptID !== params.get("receipt")) {
        setStateFromDoc(params.get("receipt"));
      }
    } else if (params.has("debugPage")) {
      setPage(parseInt(params.get("debugPage")));
    } else if (params.has("receipt")) {
      if (receiptID !== params.get("receipt")) {
        setPage(4);
        setReceiptID(params.get("receipt"));
        // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
        mixpanel.track("Load Selection", {
          ReceiptID: params.get("receipt"),
        });
      }
    } else {
      sessionStorage.removeItem("payerSession");
      sessionStorage.removeItem("askedForName");
      mixpanel.track("Load Home");
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function processData(sourceData) {
    sessionStorage.setItem("isPartyLeader", "true");
    console.log(sourceData);
    const reportedTotal = sourceData.totalAmount.data;
    setTotal(reportedTotal);
    const reportedTax = sourceData.taxAmount.data ?? 0; // if no tax found, set to 0
    setTaxAmount(reportedTax);
    const amounts = sourceData.amounts;
    const limit = amounts.length;
    const amountResults = [];
    let sumLineItemTotal = 0;
    let ocrSubtotal = 0;
    // We have to process the OCR results because sometimes it has duplicate amounts.
    // They are registered under the same index, so if we can just grab the max (overpaying > underpaying) approximation
    // for each index, then we should have the best guess.
    let processedIndices = [];
    for (let baseIndex = 0; baseIndex < limit; baseIndex++) {
      let i = amounts[baseIndex].index;
      // console.log(i);
      // console.log(processedIndices);
      if (!processedIndices.includes(i)) {
        processedIndices.push(i);
        const matchingIndices = amounts.filter((amount) => amount.index === i);
        let maxData = 0;
        let maxIndex = 0;
        for (let k = 0; k < matchingIndices.length; k++) {
          if (matchingIndices[k].data > maxData) {
            maxData = matchingIndices[k].data;
            maxIndex = k;
          }
        }
        if (matchingIndices[maxIndex]) {
          let itemText = matchingIndices[maxIndex].text;
          if (
            itemText.toLowerCase().includes("total") ||
            matchingIndices[maxIndex].data === reportedTotal ||
            matchingIndices[maxIndex].data.toFixed(2) ===
              (reportedTotal - reportedTax).toFixed(2)
          ) {
            // this is total
            if (itemText.toLowerCase().includes("subtotal")) {
              ocrSubtotal = matchingIndices[maxIndex].data;
            }
          } else if (itemText.toLowerCase().includes("tax")) {
            // this is tax
            console.log(
              "Tax matches?",
              matchingIndices[maxIndex].data === sourceData.taxAmount.data
            );
          } else if (itemText.toLowerCase().includes("credit card")) {
            // this is a card charge
          } else if (itemText.toLowerCase().includes("tip")) {
            // this is a suggested tip amount
          } else if (itemText.toLowerCase().includes("amount due")) {
            // this is a total
          } else {
            // this is an actual line item
            matchingIndices[maxIndex].index = amountResults.length;
            matchingIndices[maxIndex].itemName = trimPrice(
              matchingIndices[maxIndex].text,
              matchingIndices[maxIndex].data
            );
            sumLineItemTotal += matchingIndices[maxIndex].data;
            amountResults.push(matchingIndices[maxIndex]);
          }
        }
      }
    }

    console.log(amountResults);
    setAmounts(amountResults);
    setCalculatedTotal(sumLineItemTotal);

    const taxPercent = reportedTax / sumLineItemTotal;
    setEstimatedTaxRate(taxPercent);

    // setPage(1);
    // TODO: check for undefined values and prompt input
    const docID = await createFirestoreSession(
      sourceData,
      amountResults,
      reportedTotal,
      sumLineItemTotal,
      ocrSubtotal,
      reportedTax,
      taxPercent
    );
    mixpanel.track("Process Receipt", { "Receipt ID": docID });

    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?receipt=" +
      docID;
    window.history.pushState({ path: newurl }, "", newurl);
    setReceiptID(docID);
    localStorage.setItem("receiptId", docID);
  }
  return (
    <div className="App">
      {page === 0 && (
        <Capture advancePage={() => setPage(1)} processData={processData} />
      )}
      {page === 1 && (
        <PartyCount
          goBack={() => {
            if (window.confirm("Are you sure you want to re-scan?")) {
              window.location.href = window.location.href.substring(
                0,
                window.location.href.indexOf("?")
              );
            }
          }}
          count={partyCount}
          changeCount={(val) => setPartyCount(partyCount + val)}
          handleNext={() => setPage(2)}
        />
      )}
      {/* {
        page === 2 && (
          <TipSelectPage />
        )
      } */}
      {page === 2 && (
        <UsernameInput
          goBack={() => setPage(1)}
          count={partyCount}
          username={username}
          setUsername={setUsername}
          receiptID={receiptID}
          handleNext={() => setPage(3)}
        />
      )}

      {page === 3 &&
        (receiptID ? (
          <Confirmation
            receiptID={receiptID}
            amounts={amounts}
            goBack={() => setPage(2)}
            originalOCRTotal={total ?? 0}
            taxDollars={taxAmount}
            calculatedSubtotal={calculatedTotal}
            estimatedTaxRate={estimatedTaxRate}
            partyMemberCount={partyCount}
            ownerVenmoHandle={username}
          />
        ) : (
          <LoadingScreen goBack={() => setPage(2)} count={partyCount} />
        ))}
      {page === 4 && <Selection receiptID={receiptID} />}
    </div>
  );
}

export default App;
