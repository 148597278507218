import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import NightlifeSplit from "./routes/NightlifeSplit";

const container = document.getElementById("root");
const root = createRoot(container);
mixpanel.init("63faff05f7e55b2f3d2611ccc670070c", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App id="appElement" />}>
          {/* <Route path="" element={<Capture />} /> */}
          {/* <Route path="confirmation" element={<Confirmation />} /> */}
        </Route>
        <Route path="/nightlife-in-split" element={<NightlifeSplit />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
