// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { trimPrice } from "./numberString";
// import { getAnalytics } from "firebase/analytics";
import {
  addDoc,
  collection,
  getFirestore,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  Timestamp,
  setDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtoFFfuJNKx5oQqYfnF235Ct95jqbEbT0",
  authDomain: "scan-split.firebaseapp.com",
  projectId: "scan-split",
  storageBucket: "scan-split.appspot.com",
  messagingSenderId: "1040186648477",
  appId: "1:1040186648477:web:79866443f59379bdb47174",
  measurementId: "G-D7580DDFVB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
export default db;

export const createFirestoreSession = async (
  sourceData,
  amounts,
  reportedTotal,
  sumLineItemTotal,
  ocrSubtotal,
  reportedTax,
  estimatedTaxRate
) => {
  const sessionsColRef = collection(db, "sessions");
  const docRef = await addDoc(sessionsColRef, {
    sourceData: JSON.stringify(sourceData),
    location: sourceData.location ?? "Location not found",
    merchantAddress: sourceData.merchantAddress?.data ?? "",
    merchantName: sourceData.merchantName?.data ?? "",
    timestamp: Timestamp.now(),
    ocrTotal: reportedTotal ?? 0,
    itemSumTotal: sumLineItemTotal,
    ocrSubtotal: ocrSubtotal,
    ocrTax: reportedTax ?? 0,
    partyMembers: 2,
    estimatedTaxRate: estimatedTaxRate ?? 0,
    tipPercent: 0.2, // default to 20
  });
  const itemsColRef = collection(docRef, "items");
  for (let i = 0; i < amounts.length; i++) {
    const newItemDocRef = doc(itemsColRef);
    setDoc(newItemDocRef, {
      id: newItemDocRef.id,
      timestamp: Timestamp.now(),
      amount: amounts[i].data,
      text: amounts[i].text,
      itemName: trimPrice(amounts[i].text, amounts[i].data),
      index: i,
      currencyCode: amounts[i].currencyCode,
      regions: amounts[i].regions.length > 0 ? amounts[i].regions[0] : [],
    });
  }

  return docRef.id;
};

export const deleteItem = async (
  receiptID,
  calculatedSubtotal,
  itemName,
  amount,
  index
) => {
  const sessionDocRef = doc(db, "sessions", receiptID);
  const sessionDocSnap = await getDoc(sessionDocRef);
  const sessionDocData = sessionDocSnap.data();
  const itemSumTotal = sessionDocData.itemSumTotal;
  const ocrTotal = sessionDocData.ocrTotal;
  const itemsColRef = collection(sessionDocRef, "items");
  const q = query(
    itemsColRef,
    where("itemName", "==", itemName),
    where("amount", "==", amount),
    where("index", "==", index)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    const itemAmount = querySnapshot.docs[0].data().amount;
    console.log(itemAmount);

    deleteDoc(doc(itemsColRef, querySnapshot.docs[0].id));
    const newTotal = ocrTotal - itemAmount;

    updateDoc(sessionDocRef, {
      itemSumTotal: itemSumTotal - itemAmount,
      ocrTotal: newTotal,
    });
  }
};
