import { useState } from "react";
import Modal from "react-modal";
import mixpanel from "mixpanel-browser";

export default function QRCodeShare({ qrCodeURL, payerSessions }) {
  const [showHelpModal, setShowHelpModal] = useState(false);
  return (
    <div className="flex flex-col">
      <Modal
        isOpen={showHelpModal}
        contentLabel="Help Info"
        shouldCloseOnOverlayClick={true}
        className="bg-black m-8 py-8 px-4 z-20"
      >
        <div className="flex flex-col items-start">
          <p className="text-2xl text-white font-display mb-4">
            What if someone's phone isn't working?
          </p>

          <p className="text-lg text-white px-2 text-left"> If someone</p>
          <div>
            <ul className="text-lg text-white px-4">
              <li>📶 doesn't have service</li>
              <li>☠️ phone is dead</li>
              <li>💨 had to leave early</li>
            </ul>
          </div>
          <p className="text-lg text-white px-2">
            someone (even you) can open a new tab with the same url and do it
            for them! Tell them to Venmo later.
          </p>
          <a
            href={window.location.href}
            target="_blank"
            rel="noreferrer"
            className="w-full text-lg text-blue-200 px-2 break-words"
          >
            {" "}
            ({window.location.href})
          </a>
        </div>
        <div className="flex flex-row gap-4 justify-center mt-8">
          <button
            className="text-white border border-white p-4"
            onClick={() => {
              setShowHelpModal(false);
            }}
          >
            OK
          </button>
        </div>
      </Modal>
      <p className="text-white text-xl p-4">
        Tell the party to scan this QR code
      </p>
      <div className="flex flex-row items-center justify-center">
        <img
          className="w-full"
          src={qrCodeURL}
          alt={"QR Code for URL: " + window.location.href}
        />
      </div>
      <button
        className="h-16 bg-[#070A2C] text-white text-md font-body font-semibold"
        onClick={() =>
          navigator.clipboard.writeText(window.location.href).then(
            function () {
              mixpanel.track("Link Copied to Clipboard");
              alert("Copied to clipboard. Send to your friends!");
              console.log("Async: Copying to clipboard was successful!");
            },
            function (err) {
              alert("We had issues copying the link: " + err);
              console.error("Async: Could not copy text: ", err);
            }
          )
        }
      >
        OR tap and share this link 🔗
      </button>
      <button
        className="h-16 bg-[#1b072c] text-white text-sm font-body font-semibold"
        onClick={() => {
          setShowHelpModal(true);
        }}
      >
        <div className="flex flex-row items-center justify-center">
          <span>My friend can't open Split Party</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            dataslot="icon"
            className="w-4 h-4 ml-2"
          >
            <path
              fillRule="evenodd"
              d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.75 8a.75.75 0 0 0 0 1.5h.75v1.75a.75.75 0 0 0 1.5 0v-2.5A.75.75 0 0 0 8.25 8h-1.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="grid grid-cols-4 m-2 gap-2">
        {payerSessions.map((session) => (
          <div key={`isIn-${session.id}`} className="text-white">
            {" "}
            {session.payerName} is in{" "}
          </div>
        ))}
      </div>
    </div>
  );
}
