import React, { useState, useRef } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import axios from "axios";
import REACT_APP_TAGGUN_API_KEY from "../keys";
import { Header } from "../components/Header";
import Modal from "react-modal";
import mixpanel from "mixpanel-browser";

// OCR Statuses
const STATUSES = {
  IDLE: "",
  FAILED: "Failed to perform OCR",
  PENDING: "Processing...",
  SUCCEEDED: "OCR processing complete",
};

export default function Capture({ advancePage, processData }) {
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [ocrState, setOcrState] = useState(STATUSES.IDLE);
  const [showModal, setShowModal] = useState(false);
  const [showAddToHomescreen, setShowAddToHomescreen] = useState(false);
  const [showScan, setShowScan] = useState(false);

  const uploadFileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    // Trigger the input file
    uploadFileInputRef.current.click();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Process image with OCR
  const readImageText = async (imageSelected) => {
    setSelectedImageSrc(URL.createObjectURL(imageSelected));
    setOcrState(STATUSES.PENDING);

    const imageType = imageSelected.type;
    const filename = "receipt." + imageType.replace(/(.*)\//g, "");
    console.log(imageType, filename);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const baseString = btoa(event.target.result);
      callAPI(baseString, filename, imageType);
    });
    reader.readAsBinaryString(imageSelected);
  };

  const callAPI = async (baseString, filename, imageType) => {
    setTimeout(() => {
      advancePage();
    }, 500);
    setOcrState(STATUSES.PENDING);
    axios
      .post(
        `https://api.taggun.io/api/receipt/v1/verbose/encoded`,
        {
          image: baseString,
          filename: filename,
          contentType: imageType,
          refresh: false,
          incognito: false,
          language: "en",
        },
        {
          headers: {
            apikey: REACT_APP_TAGGUN_API_KEY,
            accept: "application/json",
            contentType: imageType,
          },
        }
      )
      .then(
        (res) => {
          if (res.status !== 200) {
            setOcrState(STATUSES.FAILED);
            alert("Failed to scan receipt: " + res.statusText);
          } else {
            setOcrState(STATUSES.SUCCEEDED);
            processData(res.data);
          }
        },
        (err) => {
          setOcrState(STATUSES.FAILED);
          alert("Failed to scan receipt: " + err);
        }
      );
  };

  return (
    <div className="bg-background-gradient bg-cover min-h-screen">
      <Header />
      {!showScan ? (
        <div className="pb-12">
          <div className="flex flex-col md:flex-row px-8 items-center md:justify-center">
            <button
              className="w-full md:w-1/3 bg-white text-black border border-black p-4 text-lg"
              onClick={() => {
                setShowScan(true);
              }}
            >
              Split a Bill Now ➡️
            </button>
            <button
              onClick={() => {
                mixpanel.track("Opened Add to Homescreen Instructions");
                setShowAddToHomescreen(true);
              }}
              className="w-full md:w-1/3 bg-black mt-2 md:mt-0 md:ml-4 text-white p-4 text-lg"
            >
              Add to Homescreen 📲
            </button>
          </div>
          <div className="md:mt-12 flex flex-row justify-center items-center flex-wrap w-full">
            <div className="w-3/4 md:w-1/4 float-right">
              <img src={"/hero.png"} alt="iPhone showing Split Party" />
            </div>
            <div className="text-white text-left px-8 mt-4">
              <p className="text-xl font-display font-bold pr-8">
                The fastest way to split the bill with friends
              </p>
              <p className="mt-4">
                Take a pic of the receipt, choose items, instantly Venmo.
              </p>
              <p className="mt-2">No app download required.</p>
              {/* <p>Tax and tip are calculated per person.</p>
            <p>
              No more napkin math, spreadsheets, calculators or typing in prices
              by hand!
            </p> */}
            </div>
          </div>
          <Modal
            isOpen={showAddToHomescreen}
            contentLabel="Minimal Modal Example"
            shouldCloseOnOverlayClick={true}
            className="bg-black m-8 py-8 px-4 z-20 text-white"
          >
            <div className="flex flex-col items-center">
              <div>
                Add Split Party to your homescreen for easy access next time you
                have to share the bill.
              </div>
              <div className="mt-4 text-center px-12">
                <p>
                  Just tap
                  <span className="text-blue-500 border-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 inline-block"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                      />
                    </svg>
                  </span>{" "}
                  then scroll to 'Add to Home Screen'
                </p>
              </div>

              <button
                onClick={() => {
                  setShowAddToHomescreen(false);
                }}
                className="bg-black text-white m-4 p-4 text-xl font-bold border border-white"
              >
                OK
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <div>
          <Modal
            isOpen={showModal}
            contentLabel="Split Party Instructions"
            shouldCloseOnOverlayClick={true}
            className="bg-black m-8 py-8 px-4 z-20"
          >
            <div className="flex flex-col items-center bg-black text-white z-20">
              <div className="px-4 w-full flex flex-col gap-2">
                <p className="font-display text-xl">
                  {" "}
                  <b>👋 Welcome to Split Party!</b>{" "}
                </p>
                <p> 1. You pay for the party! 🥳 </p>
                <p> 2. You scan the whole receipt 🧾 </p>
                <p> 3. You double check items 🕵️ </p>
                <p> 4. You share link w/party 🔗 </p>
                <p> 4. Party picks their items ☑️ </p>
                <p> 5. Party Venmos you 🤝 </p>
              </div>
              <button
                className="bg-white text-black px-16 py-4 mt-8"
                onClick={handleCloseModal}
              >
                OK
              </button>
            </div>
          </Modal>
          {selectedImageSrc ? (
            <div>
              <img
                style={{ maxWidth: "100vw" }}
                src={selectedImageSrc}
                alt="snapshot of a receipt"
              />
              {ocrState === STATUSES.PENDING && (
                <img
                  className="App-logo"
                  alt="loading icon"
                  src="https://cdn-icons-png.flaticon.com/512/6356/6356659.png"
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "37%",
                    width: "30vw",
                    height: "30vw",
                    // backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
              )}
              {ocrState === STATUSES.FAILED && (
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "37%",
                    width: "30vw",
                    height: "30vw",
                    // backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  FAILED
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="px-4 relative">
                <div
                  className={
                    showModal
                      ? "hidden"
                      : "absolute top-4 bg-slate-600/50 text-white right-8 left-8 z-10"
                  }
                >
                  <p> 🧠 get the whole check in the pic </p>
                </div>
                <Camera
                  onTakePhoto={(dataUri) => {
                    console.log(dataUri.split(",")[1]);
                    setOcrState(STATUSES.PENDING);
                    var mimeString = dataUri
                      .split(",")[0]
                      .split(":")[1]
                      .split(";")[0];
                    console.log(mimeString);
                    setSelectedImageSrc(dataUri);
                    callAPI(
                      dataUri.split(",")[1], // base64 string
                      "receipt." + mimeString.replace(/(.*)\//g, ""), // filename
                      mimeString // image type
                    );
                  }}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  imageType={IMAGE_TYPES.JPG}
                  isSilentMode={true}
                  idealResolution={{ width: 1920 }}
                  isImageMirror={false}
                  isFullscreen={true}
                />
              </div>
              <div className="px-4 mt-4 flex flex-row items-center justify-center">
                {/* <button className="bg-black text-white py-2 px-4 mr-4">
              📸 Use Flash
              <input
                style={{ width: "0px" }}
                accept="image/*"
                type="file"
                name="ocr-image"
                capture="environment"
                onChange={(event) => {
                  readImageText(event.target.files[0]);
                }}
              />
            </button> */}
                <button
                  className="bg-black text-white py-2 px-4"
                  onClick={handleUploadButtonClick}
                >
                  💾 Upload
                  <input
                    ref={uploadFileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    name="uploaded"
                    onChange={(event) => {
                      //   setSelectedImage(event.target.files[0]);
                      //   setSelectedImageType(event.target.files[0].type);
                      readImageText(event.target.files[0]);
                    }}
                  />
                </button>
                {/* <div className="bg-black flex flex-col items-center justify-center py-2 px-4 mr-4">
              <label className="font-body font-semibold text-2xl text-white">
                📸 Use Flash{" "}
                <div className="w-0">
                  <input
                    style={{ width: "0px" }}
                    accept="image/*"
                    type="file"
                    name="ocr-image"
                    capture="environment"
                    onChange={(event) => {
                      readImageText(event.target.files[0]);
                    }}
                  />
                </div>
              </label>
            </div>
            <div className="bg-black flex flex-col items-center justify-center py-2 px-4">
              <label className="font-body font-semibold text-2xl text-white">
                💾 Upload{" "}
                <input
                  style={{ width: 0 }}
                  accept="image/*"
                  type="file"
                  name="uploaded"
                  onChange={(event) => {
                    //   setSelectedImage(event.target.files[0]);
                    //   setSelectedImageType(event.target.files[0].type);
                    readImageText(event.target.files[0]);
                  }}
                />
              </label>
            </div> */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

{
  /* <blockquote
                className="tiktok-embed max-w-[605px] min-w-[325px]"
                cite="https://www.tiktok.com/@splitparty.app/video/7292064811955932458"
                data-video-id="7292064811955932458"
              >
                {" "}
                <section>
                  {" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="@splitparty.app"
                    href="https://www.tiktok.com/@splitparty.app?refer=embed"
                  >
                    @splitparty.app
                  </a>{" "}
                  how to install spliit.party to easily split the bill with your
                  friends!{" "}
                  <a
                    title="lifehacks"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/tag/lifehacks?refer=embed"
                  >
                    #lifehacks
                  </a>{" "}
                  <a
                    title="bestapps"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/tag/bestapps?refer=embed"
                  >
                    #bestapps
                  </a>{" "}
                  <a
                    title="apps"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/tag/apps?refer=embed"
                  >
                    #apps
                  </a>{" "}
                  <a
                    title="goingout"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/tag/goingout?refer=embed"
                  >
                    #goingout
                  </a>{" "}
                  <a
                    title="collegelifehacks"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/tag/collegelifehacks?refer=embed"
                  >
                    #collegelifehacks
                  </a>{" "}
                  <a
                    target="_blank"
                    title="♬ Love You So - The King Khan &#38; BBQ Show"
                    rel="noreferrer"
                    href="https://www.tiktok.com/music/Love-You-So-6728562975734515713?refer=embed"
                  >
                    ♬ Love You So - The King Khan &#38; BBQ Show
                  </a>{" "}
                </section>{" "}
              </blockquote>{" "}
              <script async src="https://www.tiktok.com/embed.js"></script> */
}
