import { useEffect, useState } from "react";
import db from "../services/firestore.js";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import Modal from "react-modal";

export const Header = ({ count = 1 }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [receiptId, setReceiptId] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("receipt")) {
      setReceiptId(params.get("receipt"));
    }
  }, []);

  const generateIs = () => {
    if (count === 1) {
      return "I";
    }
    let result = "";
    for (let i = 0; i < count; i++) {
      result += "i";
    }
    return result;
  };
  return (
    <div>
      <Modal
        isOpen={showInfo}
        contentLabel="About Split Party"
        shouldCloseOnOverlayClick={true}
        className="bg-black m-4 py-4 px-4 z-50"
        onRequestClose={() => setShowInfo(false)}
      >
        <div className="flex flex-col">
          {/* CLOSE BUTTON */}
          <div className="text-white mb-4" onClick={() => setShowInfo(false)}>
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          {receiptId && (
            <div>
              {showFeedbackBox ? (
                <div>
                  <input
                    className="p-4 border border-white"
                    value={feedback}
                    onChange={(evt) => {
                      setFeedback(evt.target.value);
                    }}
                  />
                  <button
                    className="text-white border border-white p-4"
                    onClick={async () => {
                      if (feedback) {
                        const docRef = doc(db, "sessions", receiptId);
                        await updateDoc(docRef, {
                          feedback: arrayUnion(feedback),
                        });
                        alert("Submitted. Thanks for your note!");
                      }
                      setFeedback("");
                      setShowFeedbackBox(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <button
                  className="text-white border border-white p-4"
                  onClick={() => setShowFeedbackBox(true)}
                >
                  {" "}
                  Write feedback{" "}
                </button>
              )}
            </div>
          )}
          <div className="text-white mt-4 mb-2">
            Made by{" "}
            <a
              href="https://basedappsandgames.com"
              target="_blank"
              rel="noreferrer"
            >
              basedappsandgames.com
            </a>
          </div>
          <div className="text-white mb-4">
            <a href="mailto:hello@basedappsandgames.com">Email us</a>
          </div>
        </div>
      </Modal>
      <div className="py-2 flex flex-row justify-center items-center">
        <div className="flex-none w-[24px] ml-4" />
        <div className="flex-1 font-display font-black text-3xl text-white">
          <button
            onClick={() => {
              const userConfirmed = window.confirm(
                "You will lose your spot! Are you sure you want to go home?"
              );

              if (userConfirmed) {
                // User clicked 'OK'
                window.location.href = "/";
              }
            }}
          >
            SPL{generateIs()}TPARTY.APP
          </button>
        </div>
        <div
          className="flex-none text-white pr-4"
          onClick={() => setShowInfo(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
