export const percentToFraction = (percent, partyMembers) => {
  // Convert the number to a fixed string with a maximum of 2 decimal places.
  // For example, 33.333 will become "33.33" and 100 will become "100.00".
  let formattedNumber = percent.toFixed(2);

  if (formattedNumber === "12.50") {
    return "1/8";
  } else if (formattedNumber === "37.50") {
    return "3/8";
  } else if (formattedNumber === "62.50") {
    return "5/8";
  } else if (formattedNumber === "20.00") {
    return "1/5";
  } else if (formattedNumber === "50.00") {
    return "1/2";
  } else if (formattedNumber === "75.00") {
    if (partyMembers === 4) {
      return "3/4";
    } else if (partyMembers === 8) {
      return "6/8";
    } else if (partyMembers === 12) {
      return "8/12";
    }
  } else if (formattedNumber === "16.66") {
    return "1/6";
  } else if (formattedNumber === "33.33") {
    return "1/3";
  } else if (formattedNumber === "66.66") {
    return "2/3";
  } else if (formattedNumber === "14.28") {
    return "1/7";
  } else if (formattedNumber === "28.57") {
    return "2/7";
  }

  // If the formatted number ends in ".00", we remove the ".00" part to match the desired output.
  if (formattedNumber.endsWith(".00")) {
    formattedNumber = formattedNumber.substring(0, formattedNumber.length - 3);
  }

  return formattedNumber + "%";
};

export const trimPrice = (name, price) => {
  if (name.indexOf("$") > -1) {
    name = name.substring(0, name.indexOf("$"));
  }
  if (name.indexOf(price) > -1) {
    name = name.substring(0, name.indexOf(price));
  }
  return name;
};

export const roundUpToNearest = (value, nearest) =>
  Math.round((value + (nearest / 2 - 1)) / nearest) * nearest;
