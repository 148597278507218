function Item({ name, price, dense }) {
  if (name.indexOf("$") > -1) {
    name = name.substring(0, name.indexOf("$"));
  }
  if (name.indexOf(price) > -1) {
    name = name.substring(0, name.indexOf(price));
  }

  // Take the price out of the text name
  return (
    <div className="flex flex-row justify-between px-4 py-4 text-white font-body font-semibold text-lg">
      <p
        style={{
          maxWidth: dense ? "45vw" : "75vw",
          textAlign: "left",
        }}
      >
        {name}
      </p>
      <p>{"$" + price.toFixed(2)}</p>
    </div>
  );
}

export default Item;
