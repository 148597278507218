import Item from "../Item";
import { Header } from "../components/Header";
import { useState, useEffect } from "react";
import db from "../services/firestore.js";
import Modal from "react-modal";

import {
  query,
  collection,
  doc,
  getDoc,
  orderBy,
  onSnapshot,
  updateDoc,
  setDoc,
  Timestamp,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

export default function Selection({
  receiptID,
  confirmationEmbed = false,
  savePayerSession,
}) {
  const [calculatedTotal, setCalculatedTotal] = useState(0.0);
  const [tipPercent, setTipPercent] = useState(0.0);
  const [estimatedTaxRate, setEstimatedTaxRate] = useState(0.088);
  const [amounts, setAmounts] = useState([]);
  const [yourItemsTotal, setYourItemsTotal] = useState(0.0);

  const [yourTotal, setYourTotal] = useState(0.0);

  const [yourTaxSplit, setYourTaxSplit] = useState(0.0);
  const [yourTipSplit, setYourTipSplit] = useState(0.0);

  const [selectedItemCount, setSelectedItemCount] = useState(0);

  const [ownerVenmoHandle, setOwnerVenmoHandle] = useState("");

  const [payerSessionID, setPayerSessionID] = useState("");
  const [startedSettingSessionID, setStartedSettingSessionID] = useState(false);

  const [payerName, setPayerName] = useState(
    localStorage.getItem("payerName") ?? ""
  );
  const [showPayerNameModal, setShowPayerNameModal] = useState(true);
  const [showPickYourItemsModal, setShowPickYourItemsModal] = useState(false);

  const [imReady, setImReady] = useState(false);
  const [partyReady, setPartyReady] = useState(false);

  const [showVenmoModal, setShowVenmoModal] = useState(false);
  const [waitingOnNames, setWaitingOnNames] = useState([]);

  const setStateFromDoc = (sessionData) => {
    if (sessionData.estimatedTaxRate || sessionData.estimatedTaxRate === 0) {
      setEstimatedTaxRate(sessionData.estimatedTaxRate);
    }
    setTipPercent(sessionData.tipPercent ?? 0.0);
    setOwnerVenmoHandle(sessionData.ownerVenmoHandle ?? "");

    if (sessionData.partyReady === true || sessionData.partyReady === false) {
      setPartyReady(sessionData.partyReady === true);
    }
  };

  useEffect(() => {
    if (partyReady && imReady) {
      setShowVenmoModal(true);
    }
  }, [partyReady, imReady]);

  useEffect(() => {
    let unsub;
    let itemsQueryUnsub;
    let payerSessionQueryUnsub;
    let sessionUnsub;

    async function getDocFb() {
      sessionUnsub = onSnapshot(doc(db, "sessions", receiptID), (doc) => {
        console.log("Current data: ", doc.data());
        let sessionData = doc.data();
        setStateFromDoc(sessionData);
      });
      const docRef = doc(db, "sessions", receiptID);
      const docSnap = await getDoc(docRef);
      let sessionData = docSnap.data();
      console.log(sessionData.tipPercent);
      // setStateFromDoc(sessionData);
      unsub = onSnapshot(doc(db, "sessions", receiptID), (doc) => {
        setStateFromDoc(doc.data());
      });
      const itemsQuery = query(
        collection(db, "sessions", receiptID, "items"),
        orderBy("index", "asc")
      );

      const payerSessionQuery = query(
        collection(db, "sessions", receiptID, "payerSessions")
      );
      payerSessionQueryUnsub = onSnapshot(
        payerSessionQuery,
        (querySnapshot) => {
          const notReadyNames = [];
          querySnapshot.forEach((doc) => {
            if (doc.id !== payerSessionID) {
              const payerSessionDocData = doc.data();
              if (!payerSessionDocData.ready) {
                notReadyNames.push(payerSessionDocData.payerName);
              }
            }
          });
          setWaitingOnNames(notReadyNames);
        }
      );

      itemsQueryUnsub = onSnapshot(itemsQuery, (querySnapshot) => {
        const pulledAmounts = [];
        let totalCalculated = 0;
        let totalCostOfMyItems = 0.0;
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const price = docData.amount;
          totalCalculated += price;
          if (
            docData.payerIDs?.includes(sessionStorage.getItem("payerSession"))
          ) {
            totalCostOfMyItems += price * (1.0 / docData.payerIDs.length);
          }
          docData.id = doc.id;
          pulledAmounts.push(docData);
        });
        if (
          sessionData.estimatedTaxRate === 0 ||
          sessionData.estimatedTaxRate
        ) {
          totalCalculated =
            totalCalculated * (1 + sessionData.estimatedTaxRate);
        }

        if (
          sessionData.partyReady === true ||
          sessionData.partyReady === false
        ) {
          setPartyReady(sessionData.partyReady === true);
        }

        totalCalculated = totalCalculated * (1 + sessionData.tipPercent);
        setCalculatedTotal(totalCalculated);
        const taxSplit = totalCostOfMyItems * estimatedTaxRate;
        const tipSplit =
          (totalCostOfMyItems + taxSplit) * sessionData.tipPercent;
        setYourTaxSplit(taxSplit);
        setYourTipSplit(tipSplit);
        setYourItemsTotal(totalCostOfMyItems);
        setYourTotal(totalCostOfMyItems + taxSplit + tipSplit);
        if (sessionStorage.getItem("payerSession") !== "") {
          const payerSessionDocRef = doc(
            db,
            "sessions",
            receiptID,
            "payerSessions",
            sessionStorage.getItem("payerSession")
          );
          updateDoc(payerSessionDocRef, {
            total: totalCostOfMyItems + taxSplit + tipSplit,
          });
        }
        setAmounts(pulledAmounts);
      });
    }

    async function startPayerSession() {
      // create a new payer session IF:
      // there is no payer session (eg fresh tab)
      // or there IS a payer session in this tab but it's for a different receipt
      // AND we haven't already made a session for this tab
      if (
        (!sessionStorage.getItem("payerSession") ||
          localStorage.getItem("receiptId") !== receiptID) &&
        payerSessionID === "" &&
        !startedSettingSessionID
      ) {
        console.log("starting payer session");
        setStartedSettingSessionID(true);

        const newPayerSessionRef = doc(
          collection(db, "sessions", receiptID, "payerSessions")
        );
        console.log("SETTING NEW SESSION ID", newPayerSessionRef.id);
        setPayerSessionID(newPayerSessionRef.id);
        if (savePayerSession) {
          savePayerSession(newPayerSessionRef.id);
        }
        sessionStorage.setItem("payerSession", newPayerSessionRef.id);
        localStorage.setItem("receiptId", receiptID);
        sessionStorage.setItem("askedForName", "false");
        await setDoc(newPayerSessionRef, {
          id: newPayerSessionRef.id,
          timestamp: Timestamp.now(),
        });
      }
      // IF we are refreshing the page and on the same receipt
      // we should have a payerSession already, so don't make a new one
      else if (
        localStorage.getItem("receiptId") === receiptID &&
        sessionStorage.getItem("payerSession")
      ) {
        console.log("same session editting");

        setPayerSessionID(sessionStorage.getItem("payerSession"));
        setPayerName(localStorage.getItem("payerName") ?? "");
        if (sessionStorage.getItem("askedForName") === "true") {
          setShowPayerNameModal(false);
          setShowPickYourItemsModal(true);
        }
      } else {
        console.log("conditions not met for payer session");
      }

      getDocFb();
    }

    if (receiptID !== "") {
      startPayerSession();
    }

    return function cleanup() {
      if (unsub) unsub();
      if (itemsQueryUnsub) itemsQueryUnsub();
      if (payerSessionQueryUnsub) payerSessionQueryUnsub();
      if (sessionUnsub) sessionUnsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectItem = (item, selected) => {
    console.log(item.id);
    let newSelectedItemCount = selectedItemCount;
    const itemDocRef = doc(db, "sessions", receiptID, "items", item.id);
    if (selected) {
      newSelectedItemCount++;
      updateDoc(itemDocRef, {
        payerIDs: arrayUnion(payerSessionID),
        payerNames: arrayUnion(payerName),
      });
    } else {
      newSelectedItemCount--;
      updateDoc(itemDocRef, {
        payerIDs: arrayRemove(payerSessionID),
        payerNames: arrayRemove(payerName),
      });
    }

    setSelectedItemCount(newSelectedItemCount);

    if (payerSessionID !== "") {
      const payerSessionDocRef = doc(
        db,
        "sessions",
        receiptID,
        "payerSessions",
        payerSessionID
      );
      updateDoc(payerSessionDocRef, {
        selectedItemCount: newSelectedItemCount,
      });
    }
  };

  const handleClosePickYourItemsModal = () => {
    setShowPickYourItemsModal(false);
  };

  const handleSubmitPayerNameModal = () => {
    if (!payerName.trim()) {
      alert("Type in a name so your friends will know who you are!");
      return;
    }
    console.log(payerName);
    if (payerSessionID !== "") {
      const payerSessionDocRef = doc(
        db,
        "sessions",
        receiptID,
        "payerSessions",
        payerSessionID
      );
      updateDoc(payerSessionDocRef, {
        payerName: payerName,
      });
    }
    sessionStorage.setItem("askedForName", true);
    setShowPayerNameModal(false);
    setShowPickYourItemsModal(true);
  };

  const handleOpenVenmo = () => {
    if (!confirmationEmbed) {
      // set openedPayApp true
      const payerSessionDocRef = doc(
        db,
        "sessions",
        receiptID,
        "payerSessions",
        payerSessionID
      );
      updateDoc(payerSessionDocRef, {
        openedPayApp: true,
      });
      window.open(
        encodeURI(
          "https://venmo.com/" +
            ownerVenmoHandle +
            "?txn=pay&note=Sharing 🤝 calculated by SplitParty.app 🥳&amount=" +
            yourTotal.toFixed(2)
        )
      );
    } else {
      setShowVenmoModal(false);
    }
  };

  return (
    <div className="bg-background-gradient bg-cover min-h-screen">
      <Header />
      <Modal
        isOpen={showPayerNameModal}
        contentLabel="Name Entry"
        shouldCloseOnOverlayClick={true}
        className="bg-black m-8 py-8 px-4 z-20"
      >
        <div className="flex flex-col items-center">
          <span className="text-5xl text-white font-display mb-4">
            {confirmationEmbed
              ? "Enter your name"
              : "Welcome to Split Party! What should we call you?"}
          </span>
          <input
            className="font-display text-5xl text-center w-48"
            placeholder="Mina"
            value={payerName}
            onChange={(evt) => {
              setPayerName(evt.target.value);
              localStorage.setItem("payerName", evt.target.value);
            }}
          />
        </div>
        <div className="flex flex-row gap-4 justify-center mt-8">
          <button
            className="text-white border border-white p-4"
            onClick={handleSubmitPayerNameModal}
          >
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showPickYourItemsModal}
        contentLabel="Pick your items"
        shouldCloseOnOverlayClick={true}
        className="bg-black m-8 py-8 px-4 z-20"
      >
        <div className="flex flex-col items-center">
          <span className="text-3xl text-white font-display mb-4">
            {confirmationEmbed
              ? "Pick your items and make sure everyone else picks theirs"
              : "Pick all the items you had and will pay for"}
          </span>
        </div>
        <div className="flex flex-row gap-4 justify-center mt-8">
          <button
            className="text-white border border-white p-4"
            onClick={handleClosePickYourItemsModal}
          >
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showVenmoModal}
        contentLabel="Venmo Info"
        shouldCloseOnOverlayClick={true}
        className="bg-black m-8 py-8 px-4 z-20"
      >
        <div
          onClick={() => {
            setShowVenmoModal(false);
          }}
          className="text-white float-right"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl text-white font-display mb-4">
            {confirmationEmbed
              ? "People are Venmoing you. If you make changes, people may need to recalculate."
              : "It's time to Venmo! If Venmo doesn't open, come back and tap it again."}
          </span>
          <span className="text-xl text-white font-body mb-4">
            Sending @{ownerVenmoHandle} ${yourTotal.toFixed(2)}
          </span>
        </div>
        <div className="flex flex-row gap-4 justify-center mt-8">
          <button
            className="text-white border border-white p-4"
            onClick={handleOpenVenmo}
          >
            {confirmationEmbed ? "Adjust Anyway" : "Open Venmo"}
          </button>
        </div>
      </Modal>
      <div className="px-8 text-2xl text-white mb-4">
        {" "}
        Select any items you had
      </div>
      <div
        className="w-full px-4 py-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "start",
        }}
      >
        <div className="font-body font-bold text-2xl text-white">
          Party Total
        </div>
        <div className="flex flex-row">
          <div className="font-body font-bold text-2xl text-white">
            {"$" + calculatedTotal.toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{ width: "100vw", height: "1px", backgroundColor: "#F5F5F5" }}
      />
      {amounts.map(
        (element, index) =>
          element && (
            <div
              key={index}
              className={
                (element.payerIDs?.includes(payerSessionID)
                  ? "bg-white/40 border border-white"
                  : "bg-white/20") + " mx-4 my-2 flex flex-row justify-end"
              }
              onClick={() => {
                if (element.payerIDs?.includes(payerSessionID)) {
                  selectItem(element, false);
                } else {
                  selectItem(element, true);
                }
              }}
            >
              <div style={{ flexGrow: 4 }}>
                <Item name={element.text} price={element.amount} dense={true} />
                <p className="text-white text-left px-4 pb-4">
                  {" "}
                  {element.payerNames?.join(", ")}{" "}
                </p>
              </div>
            </div>
          )
      )}
      <div style={{ height: "212px" }}></div>
      <div className="fixed-actions-container">
        <div className="flex flex-row justify-end items-center px-[16px] py-[8px]">
          <div style={{ flexDirection: "column", flexGrow: 10 }}>
            <p className="body-small"> YOUR ITEMS </p>
            <p className="title-sm">{"$" + yourItemsTotal.toFixed(2)}</p>
          </div>
          <div
            style={{ flexDirection: "column", flexGrow: 1 }}
            className="text-right"
          >
            <p className="text-right text-[10px]">
              Tax ({(estimatedTaxRate * 100).toFixed(2) + "%"})
            </p>
            <p className="text-right text-[16px]">
              {" "}
              {"$" + yourTaxSplit.toFixed(2)}{" "}
            </p>
          </div>
          <div style={{ flexDirection: "column", flexGrow: 1 }}>
            <p className="text-right text-[10px]">
              Tip ({(tipPercent * 100).toFixed(0) + "%"})
            </p>
            <p className="text-right text-[16px]">
              {" "}
              {"$" + yourTipSplit.toFixed(2)}{" "}
            </p>
          </div>
        </div>
        <div
          className="flex flex-row justify-between px-[16px] py-[8px]"
          style={{ borderTop: "1px solid #F5F5F5" }}
        >
          <p className="title-md"> Total =</p>

          <p
            className="title-md"
            onClick={() =>
              navigator.clipboard.writeText(yourTotal.toFixed(2)).then(
                function () {
                  console.log("Async: Copying to clipboard was successful!");
                  alert("Copied total to clipboard");
                },
                function (err) {
                  console.error("Async: Could not copy text: ", err);
                }
              )
            }
          >
            {" "}
            {"$" + yourTotal.toFixed(2)}
          </p>
        </div>
        {confirmationEmbed && <div className="h-20 flex" />}
        {!confirmationEmbed && (
          <div>
            {(!imReady || !partyReady) && (
              <button
                className="w-screen h-[96px] px-1 bg-black border-0 text-white text-xl font-bold"
                onClick={() => {
                  // set ready true
                  const payerSessionDocRef = doc(
                    db,
                    "sessions",
                    receiptID,
                    "payerSessions",
                    payerSessionID
                  );
                  updateDoc(payerSessionDocRef, {
                    ready: !imReady,
                  });
                  setImReady(!imReady);
                }}
              >
                {imReady
                  ? "Waiting for others (" + waitingOnNames.join(", ") + ")"
                  : "I've added all my items ✅"}
              </button>
            )}
            {partyReady && imReady && (
              <button
                className="w-screen h-[64px] bg-black border-0 text-white text-2xl font-bold"
                onClick={() => {
                  setShowVenmoModal(true);
                }}
              >
                Open Venmo 💸
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

// CODE GOES AFTER THE <ITEM>
// {element.selected ? (
//   <div className="div-add">
//     {!element.editingPercentage && (
//       <button
//         className="btn-capsule flex items-center justify-center"
//         onClick={() => {
//           if (element.percentage === 100) {
//             // DE-SELECT
//             const newAmounts = [...amounts];
//             newAmounts[index].selected = false;
//             setAmounts(newAmounts);
//             return;
//           } else if (
//             element.percentage ===
//             (1 / partyMembers) * 100
//           ) {
//             const newAmounts = [...amounts];
//             newAmounts[index].percentage = 100;
//             setAmounts(newAmounts);
//           } else {
//             // if custom
//             const newAmounts = [...amounts];
//             newAmounts[index].percentage =
//               (1 / partyMembers) * 100;
//             setAmounts(newAmounts);
//           }
//         }}
//       >
//         {element.percentage === 100 ? (
//           // trash icon
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={1.5}
//             stroke="currentColor"
//             className="w-4 h-4"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
//             />
//           </svg>
//         ) : element.percentage === (1 / partyMembers) * 100 ? (
//           "Just me"
//         ) : (
//           "Shared"
//         )}
//       </button>
//     )}
//     {element.editingPercentage ? (
//       <div>
//         <input
//           className="text-black mr-2"
//           autoFocus
//           style={{ borderRadius: "4px", maxWidth: "60px" }}
//           defaultValue={`${
//             typeof element.percentage === "number"
//               ? element.percentage.toFixed(2)
//               : element.percentage.length > 5
//               ? element.percentage.substring(0, 5)
//               : element.percentage
//           }`}
//           onChange={(evt) => {
//             const newAmounts = [...amounts];
//             const newPercent = evt.target.value;
//             newAmounts[index].proposedPercentage = newPercent;
//             editCustomPercentage(index, false);
//           }}
//         />
//       </div>
//     ) : (
//       <p
//         className="title-md"
//         onClick={() => {
//           const newAmounts = [...amounts];
//           newAmounts[index].proposedPercentage =
//             newAmounts[index].percentage;
//           newAmounts[index].editingPercentage = true;
//           setAmounts(newAmounts);
//         }}
//       >
//         {percentToFraction(element.percentage)}
//       </p>
//     )}
//     {element.editingPercentage ? (
//       <button
//         className="flex items-center justify-center"
//         onClick={() => {
//           editCustomPercentage(index);
//           const newAmounts = [...amounts];
//           newAmounts[index].editingPercentage = false;
//           setAmounts(newAmounts);
//         }}
//       >
//         {/* OK button */}
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//           />
//         </svg>
//       </button>
//     ) : (
//       <button
//         className={"btn-capsule"}
//         onClick={() => {
//           // cycle through options
//           if (element.percentage === 100) {
//             // if currently at 100, switch to "shared mode"
//             const newAmounts = [...amounts];
//             newAmounts[index].percentage =
//               (1 / partyMembers) * 100;
//             setAmounts(newAmounts);
//           } else {
//             // if currently in "shared mode" or "custom mode"
//             // go to custom edit
//             const newAmounts = [...amounts];
//             newAmounts[index].editingPercentage = true;
//             if (!newAmounts[index].proposedPercentage) {
//               newAmounts[index].proposedPercentage =
//                 newAmounts[index].percentage.toString();
//             } else {
//               newAmounts[index].percentage =
//                 newAmounts[index].proposedPercentage;
//             }

//             setAmounts(newAmounts);
//           }
//         }}
//       >
//         {element.percentage === 100 // in just me mode
//           ? "Shared"
//           : element.percentage === (1 / partyMembers) * 100 // in shared mode
//           ? "Custom"
//           : "Edit"}
//       </button>
//     )}
//   </div>
// ) : (
//   <button
//     className="btn-add"
//     onClick={() => {
//       const newAmounts = [...amounts];
//       newAmounts[index].selected = true;
//       newAmounts[index].percentage = 100;
//       setAmounts(newAmounts);
//     }}
//   >
//     <p className="title-md">Add</p>
//   </button>
// )}

// EDIT CUSTOM PERCENTAGE
// const editCustomPercentage = (index, showErrors = true) => {
//     const newAmounts = [...amounts];
//     let proposedPercentage;
//     if (newAmounts[index].proposedPercentage.includes("/")) {
//       const [numerator, denominator] =
//         newAmounts[index].proposedPercentage.split("/");
//       console.log("denomiator: " + denominator);
//       if (
//         isNaN(numerator) ||
//         isNaN(denominator) ||
//         denominator === "" ||
//         numerator === ""
//       ) {
//         if (showErrors) {
//           alert("Please enter a valid fraction");
//         }
//         console.log("invalid fraction");
//         newAmounts[index].percentage = 0;
//         setAmounts(newAmounts);
//         return;
//       }
//       proposedPercentage = (numerator / denominator) * 100;
//     } else {
//       proposedPercentage = Number.parseFloat(
//         newAmounts[index].proposedPercentage.replace("%", "")
//       );
//     }
//     if (Number.isNaN(proposedPercentage)) {
//       newAmounts[index].percentage = 0;
//       setAmounts(newAmounts);
//       if (showErrors) {
//         alert("Please enter a number");
//       }
//     } else if (proposedPercentage < 0 && showErrors) {
//       alert("Percentage can't be below 0");
//     } else {
//       if (proposedPercentage > 100 && showErrors) {
//         if (
//           !window.confirm(
//             "You entered a percentage higher than 100, are you sure you want to pay more than the listed price?"
//           )
//         ) {
//           // selected no
//           return;
//         }
//       }
//       newAmounts[index].percentage = proposedPercentage;
//       setAmounts(newAmounts);
//     }
//   };
