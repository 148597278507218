import Modal from "react-modal";
import { useState } from "react";

function TipSelector({ changeTip, tipPercent, subtotalPlusTax }) {
  const [customTip, setCustomTip] = useState("");
  const [showingCustomTipModal, setShowingCustomTipModal] = useState(false);
  const [tipInDollars, setTipInDollars] = useState(false); // default to percentage

  const handleSubmitCustomTipModal = () => {
    try {
      const tipFloat = parseFloat(customTip);
      if (isNaN(tipFloat) || tipFloat < 0) {
        alert(
          tipInDollars
            ? "Enter a dollar amount or cancel"
            : "Enter a percentage 0-100 or cancel"
        );
        return;
      }
      if (tipFloat > 100) {
        if (!tipInDollars) {
          alert("Are you sure you want to tip more than 100%?");
        }
      }
      changeTip(tipFloat, tipInDollars);
      setShowingCustomTipModal(false);
    } catch {
      alert("Please enter a number 0-100 and try again.");
    }
  };

  const handleCancelCustomTipModal = () => {
    setCustomTip("");
    setShowingCustomTipModal(false);
  };

  const calculatePercentageForDollarTips = () => {
    const tipFloat = parseFloat(customTip);
    if (isNaN(tipFloat) || tipFloat < 0) {
      return "";
    }
    return (
      "You're tipping " +
      ((tipFloat / subtotalPlusTax) * 100.0).toFixed(2) +
      "%"
    );
  };

  // Take the price out of the text name
  return (
    <div
      className="flex-space-between"
      style={{ borderTop: "1px solid #F5F5F5" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <p className="font-body text-xl"> Tip </p>
        <div className="dense-flex-container" style={{ marginLeft: "12px" }}>
          <button
            className={tipPercent === 15 ? "btn-square-selected" : "btn-square"}
            onClick={() => changeTip(15, false)}
          >
            15%
          </button>
          <button
            className={tipPercent === 18 ? "btn-square-selected" : "btn-square"}
            onClick={() => changeTip(18, false)}
          >
            18%
          </button>
          <button
            className={tipPercent === 20 ? "btn-square-selected" : "btn-square"}
            onClick={() => changeTip(20, false)}
          >
            20%
          </button>
          <button
            className={
              tipPercent !== 15 && tipPercent !== 18 && tipPercent !== 20
                ? "min-w-[48px] h-[48px] border-0 text-[#070A2C] text-[16px] bg-[#00FFC2] px-2"
                : "min-w-[48px] h-[48px] border-0 text-white text-[16px] ml-4"
            }
            onClick={() => {
              setShowingCustomTipModal(true);
            }}
          >
            {tipPercent !== 15 && tipPercent !== 18 && tipPercent !== 20
              ? (tipInDollars ? "$" : "") +
                customTip +
                (tipInDollars ? "" : "%")
              : "Custom"}
          </button>
          <Modal
            isOpen={showingCustomTipModal}
            contentLabel="Custom Tip Entry"
            shouldCloseOnOverlayClick={true}
            className="bg-black m-8 py-8 px-4 z-20"
          >
            <div className="flex flex-col items-center">
              <div className="flex flex-row items-center mb-4">
                <div
                  className={`text-2xl mr-2 font-display border px-4 ${
                    tipInDollars
                      ? "text-white border-white"
                      : "text-gray-500 border-gray-500"
                  }`}
                  onClick={() => {
                    setTipInDollars(true);
                  }}
                >
                  {" "}
                  Dollars{" "}
                </div>
                <div
                  className={`text-2xl ml-2 font-display border px-4 ${
                    !tipInDollars
                      ? "text-white border-white"
                      : "text-gray-500 border-gray-500"
                  }`}
                  onClick={() => {
                    setTipInDollars(false);
                  }}
                >
                  {" "}
                  Percent{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <span
                  className={`text-7xl font-display ${
                    tipInDollars ? "text-white" : "text-gray-500"
                  }`}
                  onClick={() => {
                    setTipInDollars(true);
                  }}
                >
                  $
                </span>
                <input
                  className="font-display mx-2 text-7xl text-center w-44"
                  placeholder="25"
                  value={customTip}
                  onChange={(evt) => {
                    setCustomTip(evt.target.value);
                  }}
                />
                <span
                  className={`text-7xl font-display  ${
                    !tipInDollars ? "text-white" : "text-gray-500"
                  }`}
                  onClick={() => {
                    setTipInDollars(false);
                  }}
                >
                  %
                </span>
              </div>
              {tipInDollars && (
                <div className="mt-4 text-white">
                  {calculatePercentageForDollarTips()}
                </div>
              )}
            </div>
            <div className="flex flex-row gap-4 justify-center mt-8">
              <button
                className="text-white p-4"
                onClick={handleCancelCustomTipModal}
              >
                CANCEL
              </button>
              <button
                className="text-white border border-white p-4"
                onClick={handleSubmitCustomTipModal}
              >
                OK
              </button>
            </div>
          </Modal>
        </div>
        <p style={{ fontWeight: 300, fontSize: 20, marginLeft: "12px" }}> = </p>
      </div>
      <p
        onClick={() => {
          setTipInDollars(true);
          setCustomTip(((tipPercent / 100.0) * subtotalPlusTax).toFixed(2));
          setShowingCustomTipModal(true);
        }}
      >
        {"$" + ((tipPercent / 100.0) * subtotalPlusTax).toFixed(2)}
      </p>
    </div>
  );
}

export default TipSelector;
