import React from "react";
import { Header } from "../components/Header";

export default function LoadingScreen({ goBack, count }) {
  return (
    <div className="bg-background-gradient bg-cover min-h-screen flex flex-col justify-stretch">
      <Header />
      <div className="absolute top-0 left-0">
        <button
          className="top-button mt-4 ml-4 border-0"
          onClick={() => goBack()}
        >
          <svg
            width="13"
            height="21"
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.58 0.460035C11.8557 0.746552 12.0063 1.13079 11.9988 1.52831C11.9913 1.92584 11.8263 2.30412 11.54 2.58003L3.66401 10L11.54 17.42C11.6887 17.5549 11.8088 17.7183 11.8932 17.9004C11.9776 18.0825 12.0246 18.2798 12.0314 18.4804C12.0382 18.681 12.0046 18.881 11.9327 19.0684C11.8608 19.2558 11.752 19.4269 11.6127 19.5715C11.4735 19.7161 11.3066 19.8313 11.122 19.9102C10.9375 19.9892 10.7389 20.0303 10.5382 20.031C10.3374 20.0318 10.1386 19.9923 9.95336 19.9149C9.76816 19.8374 9.6004 19.7235 9.46001 19.58L0.460015 11.08C0.314842 10.9401 0.199369 10.7724 0.120498 10.5868C0.0416265 10.4012 0.000976562 10.2017 0.000976562 10C0.000976562 9.7984 0.0416265 9.59884 0.120498 9.41328C0.199369 9.22772 0.314842 9.05996 0.460015 8.92003L9.46001 0.420035C9.74653 0.144374 10.1308 -0.00625661 10.5283 0.00124382C10.9258 0.00874426 11.3041 0.173762 11.58 0.460035Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="text-white whitespace-pre absolute top-20 left-0 right-0 flex flex-row justify-center">
        Receipt Scanning Powered by{" "}
        <a
          href="https://taggun.io"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          Taggun.io
        </a>
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <img
          className="App-logo"
          alt="loading icon"
          src="https://cdn-icons-png.flaticon.com/512/6356/6356659.png"
          style={{
            position: "absolute",
            top: "40%",
            left: "37%",
            width: "30vw",
            height: "30vw",
            // backgroundColor: "white",
            borderRadius: "50%",
          }}
        />
      </div>
    </div>
  );
}
