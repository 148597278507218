export const PayerSessionSummary = ({
  payerSessions,
  subtotalPlusTax,
  tipPercent,
  otherPayerTotal,
  myPayerSessionID,
}) => {
  return (
    <div className="p-4 text-white flex flex-col">
      {payerSessions.map(
        (session) =>
          session.id !== myPayerSessionID && (
            <div key={session.id} className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <div>{session.payerName}</div>

                <div className="ml-1">
                  {session.ready ? (
                    // check mark
                    <div className="text-green-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  ) : (
                    // pending clock
                    <div className="text-yellow-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <div>${(session.total ?? 0).toFixed(2)}</div>
            </div>
          )
      )}
      <hr className="my-2" />

      <div key={"my-total"} className="flex flex-row justify-between">
        <div>You</div>
        <div className="flex flex-row">
          $
          {(
            subtotalPlusTax +
            (tipPercent / 100.0) * subtotalPlusTax -
            otherPayerTotal
          ).toFixed(2)}
        </div>
      </div>
      <div key={"total"} className="flex flex-row justify-between">
        <div className="font-bold">Total</div>
        <div className="flex flex-row font-bold">
          $
          {(subtotalPlusTax + (tipPercent / 100.0) * subtotalPlusTax).toFixed(
            2
          )}
        </div>
      </div>
    </div>
  );
};

// COLORS
// const colors = [
//   "#01e0bb",
//   "#8901f0",
//   "#ca3ee8",
//   "#faff8c",
//   "#ffab8c",
//   "#5dbf21",
//   "#19a67b",
//   "#8dfbb8",
// ];
// const colorClasses = [
//   "text-[#01e0bb]",
//   "text-[#8901f0]",
//   "text-[#ca3ee8]",
//   "text-[#faff8c]",
//   "text-[#ffab8c]",
//   "text-[#5dbf21]",
//   "text-[#19a67b]",
//   "text-[#8dfbb8]",
// ];
