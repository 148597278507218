import React from "react";
import { Header } from "../components/Header";

export default function NightlifeSplit() {
  return (
    <div className="App">
      <div className="bg-background-gradient bg-cover min-h-screen pb-12">
        <Header />
        <div className="mt-1 mb-4">
          <img
            className="object-cover h-64 md:h-96 w-screen"
            src="https://www.centralclub.hr/content/photo-gallery/2018-06-10-16-39-900684602/711852790-775013328-147863660.jpg"
            alt="Bustling Split Party scene in a nightclub in Split, confetti everywhere, party people dancing and drinking"
          />
        </div>
        <div className="text-white text-left px-8">
          <h1 className="text-3xl"> Nightlife in Split </h1>
          <h1 className="text-2xl"> Where to Party </h1>
          <p className="mt-4">
            {" "}
            Is Split a party town? Yes! Here are the top places to party in
            Split. And remember to use{" "}
            <a className="text-blue-300" href="https://splitparty.app">
              Split Party
            </a>{" "}
            to share the bill with friends when going out.
          </p>
          <h2 className="text-xl mt-4"> Old Town (Diocletian's Palace) </h2>
          <p>
            {" "}
            Start your party night in the Old Town of Split with some light bar
            hopping through Diocletian's Palace. We highly recommend{" "}
            <a
              href="https://www.paradisobarsplit.com/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Paradiso.
            </a>
          </p>
          <div className="flex flex-row justify-center my-4">
            <img
              src="https://www.paradisobarsplit.com/wp-content/uploads/2021/03/19.png"
              alt="Bartender making drinks at a vibey bar for a Split party"
              className="h-32"
            />
          </div>
          <p className="text-sm">
            {" "}
            Note: Other blogs recommend Gaga Cocktail Bar or La Bodega, which
            have sadly been permanently closed.{" "}
          </p>
          <p className="my-4">
            You can party it up with locals on the "west coast" of Matejuška and
            Split, since it's cheaper to buy a beer here than in the town
            centre. Checkout Luxor for a cute café bar to kick off your Split
            party night.
          </p>

          <div>
            <h2 className="text-xl mt-8 underline">
              <a
                href="https://www.club-vanilla.hr/index-en.html"
                target="_blank"
                rel="noreferrer"
              >
                Vanilla Club
              </a>
            </h2>
            <div className="flex flex-row justify-center my-4">
              <img
                className="object-cover h-64 md:h-96 w-full"
                src="https://www.club-vanilla.hr/assets/images/galerija/picture_02.jpg"
                alt="A man and woman at a Split party go in for a kiss in front of an LED wall at a nightclub"
              />
            </div>
            <p className="mt-4">
              Experience the Ultimate Split Party Scene at Vanilla Club! Trust
              us. It's really not that vanilla.
            </p>
            <p className="mt-4">
              Looking for the hottest spot in Split to have an unforgettable
              night out? Look no further than Vanilla Club, the premier
              destination for partygoers in town! With a rich history and a
              reputation for non-stop fun, Vanilla Club is your go-to party zone
              in Split.
            </p>

            <p className="mt-4">
              The club was born with a vision of providing top-notch
              entertainment and quality service, and over the years, they've
              become synonymous with a great night out. At Vanilla Club, there
              are no boundaries to the fun you can have. They've continually
              expanded the offerings, including services and the club's space,
              to ensure you have the best experience possible.
            </p>

            <p className="mt-4">
              To join the party, just make sure you're dressed to impress! While
              entry is typically free, keep an eye out for special nights
              featuring performances by local and international artists when
              there may be a cover charge.
            </p>

            <p className="mt-4">
              Don't miss out on the ultimate Split party experience – join the
              fun at Vanilla Club and dance the night away!
            </p>

            <h3 className="text-lg mt-4">
              Q1: Can you provide more information about the types of events or
              performances that Vanilla Club hosts on special nights?
            </h3>
            <p className="mt-2">
              Vanilla Club hosts parties like a New Year's Eve party. There's
              live music nights with singing and bands as well as guest DJs. You
              can even book a corporate party, a bachelorette Split party or a
              wedding party at the venue.
            </p>
            <h3 className="text-lg mt-4">
              Q2: What is the club's dress code and are there any specific
              guidelines for attire?
            </h3>
            <p className="mt-2">
              {" "}
              Classy club clothing, cocktail dresses, button up shirts are
              great. But a suave tshirt or blouse will fit the bill too.
            </p>
            <div className="flex flex-row justify-center my-4">
              <img
                className="object-cover h-64 md:h-96 w-full"
                src="https://www.club-vanilla.hr/assets/images/galerija/picture_03.jpg"
                alt="Bustling Split party scene at a nightclub"
              />
            </div>
            <h3 className="text-lg mt-4">
              Q3: Are there any unique features or attractions within Vanilla
              Club that set it apart from other party venues in Split?
            </h3>
            <p className="my-2">
              They have a huge stage, great lights, and amazing sound system
              that you won't find elsewhere.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-xl mt-8 underline">
              <a
                href="https://www.centralclub.hr/home"
                target="_blank"
                rel="noreferrer"
              >
                Central
              </a>
            </h2>
            <div className="flex flex-row justify-center my-4">
              <img
                className="object-cover h-64 md:h-96 w-full"
                src="https://www.centralclub.hr/content/homepage/photos/2018-06-08-16-00-7275-hero.png"
                alt="Awesome Split party at a nightclub"
              />
            </div>
            <p className="mt-4">
              There is a huge Split Party Scene at Central Club, a renovated 360
              Cinemahouse. One of the more expensive places, it defines the
              ritzy Split party experience.
            </p>
            <p className="mt-4">
              Central Nightclub is the talk of the town, and for good reason!
              This relatively new club has taken Split by storm, thanks to its
              spacious layout and breathtaking interior design. When summer
              rolls around, Central opens its doors every night to welcome a
              lineup of talented DJs, both local and international, for a series
              of thematic evening parties that'll keep you dancing until dawn.
            </p>

            <p className="mt-4">
              The transformation of the former 'Central' Cinema into a two-floor
              nightclub with four bars, VIP lodges, and state-of-the-art sound
              systems is nothing short of remarkable. It's the perfect setting
              for an unforgettable night out.
            </p>

            <p className="mt-4">
              At Central, every night is a unique experience, with themes
              ranging from evergreens to disco and live performances by
              world-famous DJs. While it's undeniably one of the best nightlife
              spots in Split, keep in mind that there is usually an entrance fee
              to access the party.
            </p>

            <p className="mt-4">
              Don't miss your chance to be a part of the excitement at Central
              Nightclub – where unforgettable nights are made!
            </p>

            <h3 className="text-lg mt-4">
              Q1: Can you provide more details about the specific thematic
              evening parties that Central Nightclub hosts during the summer
              season?
            </h3>
            <p className="mt-2">
              Evergreen, plants, disco, whiteout, blackout, neon glow, and more
              for the Split Party that fits your vibe.
            </p>
            <h3 className="text-lg mt-4">
              Q2: What is the usual range for the entrance fee, and do they
              offer any special deals or promotions for entry?
            </h3>
            <p className="mt-2">
              {" "}
              The entry fee ranges from 20-40 kuna, depending on the night there
              are sometimes free entries.
            </p>
            <div className="flex flex-row justify-center my-4">
              <img
                className="object-cover h-64 md:h-96 w-full"
                src="https://www.centralclub.hr/content/photo-gallery/2018-06-10-16-39-900684602/475475355-615175101-688264108.JPG"
                alt="Bustling Split party scene at a nightclub"
              />
            </div>
            <h3 className="text-lg mt-4">
              Q3: Could you elaborate on the unique features of Central
              Nightclub's interior design that make it stand out?
            </h3>
            <p className="my-2">
              They have a massive stage, themed decorations every night, and a
              vintage vibe due to their old moviehouse roots.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
