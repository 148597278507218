import { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

export default function FinalWaiting({
  payerSessions,
  subtotalPlusTax,
  tipPercent,
  partyMemberCount,
  myPayerSessionID,
}) {
  const [numberPaid, setNumberPaid] = useState(0);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (numberPaid === partyMemberCount - 1) {
      console.log("all ready");
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [numberPaid, partyMemberCount]);

  return (
    <div className="flex flex-col">
      {showConfetti && (
        <div>
          <Confetti width={windowWidth} height={windowHeight} />
        </div>
      )}
      {
        <div className="flex flex-col items-center">
          <div className="text-center text-3xl text-white p-4">
            {numberPaid === partyMemberCount - 1
              ? "Everyone's in!"
              : "Waiting for the Venmos to roll in... 🤑"}
          </div>
          <div className="flex flex-col items-start w-3/4">
            <div className="flex flex-row justify-end items-end w-full px-2">
              <p className="text-white w-9/12 text-right text-sm mr-2">
                {" "}
                Check this box once you get their Venmo{" "}
              </p>
              <p>🔽</p>
            </div>
            {payerSessions.map(
              (payerSession) =>
                payerSession.id !== myPayerSessionID && (
                  <div key={payerSession.id} className="text-white w-full my-2">
                    <div className="flex flex-row items-start justify-center w-full">
                      <div className="flex flex-col items-start mr-2">
                        <div>{payerSession.payerName}</div>
                        <div className="">${payerSession.total.toFixed(2)}</div>
                      </div>
                      <div
                        className={
                          payerSession.openedPayApp
                            ? "text-green-300 text-left w-6/12"
                            : "text-yellow-300 text-left w-6/12"
                        }
                      >
                        {payerSession.openedPayApp
                          ? "Opened Venmo"
                          : "Waiting for Venmo"}
                      </div>
                      <div className="ml-2">
                        Got it
                        <input
                          className="ml-2"
                          type="checkbox"
                          onChange={(evt) => {
                            setNumberPaid(
                              (prev) => prev + (evt.target.checked ? 1 : -1)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="mt-4">
            <p
              className={
                numberPaid === partyMemberCount - 1
                  ? "text-3xl font-bold text-green-300"
                  : "text-3xl font-bold text-white"
              }
            >
              {numberPaid}/{partyMemberCount - 1} PAID
            </p>
          </div>
          {numberPaid === partyMemberCount - 1 && (
            <div className="mt-8 w-full">
              <p className="text-5xl font-bold text-green-300">
                SPLIT PARTY COMPLETE
              </p>
            </div>
          )}
        </div>
      }
    </div>
  );
}
