import Item from "../Item";
import TipSelector from "../TipSelector";
import Selection from "./Selection";
import QRCodeShare from "../pages/QRCodeShare";

import { useState, useEffect } from "react";
import db, { deleteItem } from "../services/firestore.js";
import {
  doc,
  updateDoc,
  query,
  addDoc,
  collection,
  getDocs,
  where,
  onSnapshot,
  increment,
} from "firebase/firestore";
import { TbDivide, TbEdit } from "react-icons/tb";
import { HiTrash } from "react-icons/hi";
import SquareButton from "../SquareButton";
import { Header } from "../components/Header";
import Modal from "react-modal";
import mixpanel from "mixpanel-browser";
import QRCode from "qrcode";
import { trimPrice } from "../services/numberString";
import ReadyCheck from "../pages/ReadyCheck.js";
import FinalWaiting from "../pages/FinalWaiting.js";

export default function Confirmation({
  amounts,
  goBack,
  originalOCRTotal,
  taxDollars,
  calculatedSubtotal,
  receiptID,
  estimatedTaxRate,
  partyMemberCount,
  ownerVenmoHandle,
}) {
  const [tipPercent, setTipPercent] = useState(20);
  const [items, setItems] = useState(amounts);
  const [subtotalPlusTax, setSubtotalPlusTax] = useState(originalOCRTotal);
  const [totalToCheckAgainst, setTotalToCheckAgainst] =
    useState(originalOCRTotal);
  const [subtotal, setSubtotal] = useState(calculatedSubtotal);
  const [showTaxEditModal, setShowTaxEditModal] = useState(false);
  const [showTotalEditModal, setShowTotalEditModal] = useState(false);
  const [showSubtotalEditModal, setShowSubtotalEditModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showDivideItemModal, setShowDivideItemModal] = useState(false);
  const [taxAmount, setTaxAmount] = useState(taxDollars);
  const [typedTax, setTypedTax] = useState(taxDollars.toFixed(2));
  const [typedTotal, setTypedTotal] = useState("");
  const [typedSubtotal, setTypedSubtotal] = useState(
    calculatedSubtotal.toFixed(2)
  );
  const [localItemsIndexEditing, setLocalItemsIndexEditing] = useState();
  const [typedEditedItemName, setTypedEditedItemName] = useState("");
  const [typedEditedItemPrice, setTypedEditedItemPrice] = useState("");
  const [typedItemDivisions, setTypedItemDivisions] = useState("");
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [payerSessions, setPayerSessions] = useState([]);
  const [myPayerSessionID, setMyPayerSessionID] = useState("");
  const [ignoreTotalToCheck, setIgnoreTotalToCheck] = useState(false);
  const [confirmationPage, setConfirmationPage] = useState("Review");

  const [reviewAmountsEdited, setReviewAmountsEdited] = useState(false);
  const [partyReady, setPartyReady] = useState(false);

  const [otherPayerTotal, setOtherPayerTotal] = useState(0.0);

  useEffect(() => {
    // check if the scanned items calculated subtotal and the scanned tax
    // add up to what was scanned on the receipt
    if (subtotal + taxAmount !== totalToCheckAgainst && !ignoreTotalToCheck) {
      console.log(subtotal);
      console.log(taxAmount);
      console.log(totalToCheckAgainst);

      console.log("Total doesn't match");
      // if the total didn't scan correctly, prompt input
      if (totalToCheckAgainst <= 0) {
        alert(
          "Oops! Looks like the total wasn't scanned properly. Please type in the bill total."
        );
        setShowTotalEditModal(true);
      }
      // if the tax didn't scan correctly, prompt input
      else if (taxAmount === 0) {
        alert(
          "Oops! Looks like the tax amount wasn't scanned properly. Please type in the tax amount."
        );
        setShowTaxEditModal(true);
      } else {
        // check the items
        // numbers are too high
        if (subtotal + taxAmount > totalToCheckAgainst) {
          let foundOneExtraItem = false;
          // check to see if there's one offender to remove
          for (let i = 0; i < amounts.length; i++) {
            if (
              subtotal + taxAmount - amounts[i].amount ===
              totalToCheckAgainst
            ) {
              foundOneExtraItem = true;
              alert(
                "Oops! Looks like we scanned an extra item. " +
                  trimPrice(amounts[i].text) +
                  " might need to be deleted."
              );
            }
          }
          // if there wasn't one offender to remove
          if (!foundOneExtraItem) {
            alert(
              "Oops! Looks like the numbers aren't adding up. Double check all the items scanned properly. Edit any errors or rescan the receipt."
            );
          }
        }
        // numbers are too low
        else {
          alert(
            "Oops! Looks like the numbers aren't adding up. Either the item prices didn't scan properly and you need to edit them, or there is a missing item and you need to rescan the receipt."
          );
        }
      }
    } else {
      console.log("Total matches");
    }
  }, [taxAmount, subtotal, amounts, totalToCheckAgainst, ignoreTotalToCheck]);

  useEffect(() => {
    let payerSessionQueryUnsub;
    const payerSessionQuery = query(
      collection(db, "sessions", receiptID, "payerSessions"),
      where("selectedItemCount", ">", 0)
    );
    payerSessionQueryUnsub = onSnapshot(payerSessionQuery, (querySnapshot) => {
      const payerSessionData = [];

      querySnapshot.forEach((doc) => {
        const payerSessionDocData = doc.data();
        payerSessionData.push(payerSessionDocData);
      });
      setPayerSessions(payerSessionData);
    });

    return function cleanup() {
      if (payerSessionQueryUnsub) payerSessionQueryUnsub();
    };
  }, [receiptID]);

  useEffect(() => {
    let newSubtotal = 0;
    for (let i = 0; i < items.length; i++) {
      newSubtotal += items[i].data;
    }
    setSubtotalPlusTax(newSubtotal + taxAmount);
    setSubtotal(newSubtotal);
    setTypedSubtotal(newSubtotal.toFixed(2));
    if (receiptID !== "") {
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, { estimatedTaxRate: taxAmount / newSubtotal });
    }
  }, [items, receiptID, taxAmount]);

  useEffect(() => {
    if (receiptID !== "") {
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, { partyMembers: partyMemberCount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyMemberCount]);

  useEffect(() => {
    if (receiptID !== "") {
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, {
        tip: (tipPercent / 100.0) * subtotalPlusTax,
        tipPercent: tipPercent / 100.0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipPercent]);

  useEffect(() => {
    if (receiptID !== "") {
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, {
        ownerVenmoHandle: ownerVenmoHandle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerVenmoHandle]);

  useEffect(() => {
    let allTotals = 0;
    payerSessions.forEach((element, index) => {
      if (element.id !== myPayerSessionID) {
        allTotals += element.total ?? 0;
      }
    });
    // setItemBasedBreakdown(itemBreakdown);
    setOtherPayerTotal(allTotals);
    // setSessions(payerSessions);
  }, [myPayerSessionID, payerSessions]);

  const handleEditTax = () => {
    setShowTaxEditModal(true);
  };

  const handleEditSubtotal = () => {
    setShowSubtotalEditModal(true);
  };

  const handleSubmitTaxEditModal = () => {
    let parsed = parseFloat(typedTax);
    if (parsed === 0 || parsed) {
      mixpanel.track("Tax Edited");
      setTaxAmount(parsed);
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, {
        ocrTax: parsed,
        estimatedTaxRate: parsed / subtotal,
      });
      setShowTaxEditModal(false);
    } else {
      alert("Enter the dollar amount of tax");
    }
  };

  const handleSubmitEditedItem = async () => {
    if (
      localItemsIndexEditing === undefined ||
      localItemsIndexEditing === null
    ) {
      alert("Sorry, we ran into a problem. Try editing again.");
      setShowEditItemModal(false);
    }

    const newItems = [...items];
    const newPrice = parseFloat(typedEditedItemPrice);
    if (isNaN(newPrice)) {
      alert("Enter a valid number for the price");
      return;
    }

    const editedItemQuery = query(
      collection(db, "sessions", receiptID, "items"),
      where("index", "==", newItems[localItemsIndexEditing].index)
    );
    const querySnapshot = await getDocs(editedItemQuery);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      updateDoc(doc.ref, {
        amount: newPrice,
        text: typedEditedItemName,
        itemName: typedEditedItemName,
      });
    });
    newItems[localItemsIndexEditing].data = newPrice;
    newItems[localItemsIndexEditing].text = typedEditedItemName;
    newItems[localItemsIndexEditing].itemName = typedEditedItemName;

    setIgnoreTotalToCheck(true); // ignore if we edit an amount

    setItems(newItems);
    setShowEditItemModal(false);

    // when we edit an item, it should RESET the Selection sessions
    setReviewAmountsEdited(true);
  };

  const handleSubmitDividedItem = async () => {
    if (
      localItemsIndexEditing === undefined ||
      localItemsIndexEditing === null
    ) {
      alert("Sorry, we ran into a problem. Try editing again.");
      setShowDivideItemModal(false);
    }

    const newItems = [...items];
    const itemDivisions = parseInt(typedItemDivisions);
    if (isNaN(itemDivisions) || itemDivisions <= 0) {
      alert("Enter a valid number to divide by");
      return;
    }
    if (itemDivisions === 1) {
      setShowDivideItemModal(false);
      return;
    }

    const dividedPrice = newItems[localItemsIndexEditing].data / itemDivisions;
    const currentName = newItems[localItemsIndexEditing].itemName;
    let newName = `[1/${itemDivisions}] - ${currentName}`;

    newItems[localItemsIndexEditing].data = dividedPrice;
    newItems[localItemsIndexEditing].text = newName;
    newItems[localItemsIndexEditing].itemName = newName;

    // let matches = currentName.match(/\d+/g);

    // Check if number extracted
    // if (matches) {
    //   const firstMatch = matches[0];
    //   const intFirstMatch = parseInt(matches[0]);
    //   if (intFirstMatch === itemDivisions) {
    //     console.log(
    //       "First number is same as item divisions: ",
    //       intFirstMatch,
    //       itemDivisions
    //     );
    //     newName = currentName.replace(firstMatch, "1");
    //     console.log(newName);
    //   } else {
    //     newName = currentName.replace(firstMatch, "1");
    //     console.log(newName);
    //   }
    // }

    const editedItemQuery = query(
      collection(db, "sessions", receiptID, "items"),
      where("index", "==", newItems[localItemsIndexEditing].index)
    );
    const querySnapshot = await getDocs(editedItemQuery);
    let currentDocData;
    querySnapshot.forEach(async (doc) => {
      // doc.data() is never undefined for query doc snapshots
      await updateDoc(doc.ref, {
        amount: dividedPrice,
        text: newName,
        itemName: newName,
      });
      currentDocData = doc.data();
    });

    const itemsToIncrementQuery = query(
      collection(db, "sessions", receiptID, "items"),
      where("index", ">=", newItems[localItemsIndexEditing].index + 1)
    );

    // increment db indices
    const incrementQuerySnapshot = await getDocs(itemsToIncrementQuery);
    await incrementQuerySnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, {
        index: increment(itemDivisions - 1),
      });
    });
    // increment local indices
    const localItemsWithHigherIndices = newItems.filter(
      (item) => item.index >= newItems[localItemsIndexEditing].index + 1
    );
    for (let i = 0; i < localItemsWithHigherIndices.length; i++) {
      newItems[localItemsIndexEditing + i + 1].index += itemDivisions - 1;
    }

    // insert the new divided items
    for (let i = 0; i < itemDivisions - 1; i++) {
      await addDoc(collection(db, "sessions", receiptID, "items"), {
        ...currentDocData,
        index: newItems[localItemsIndexEditing].index + 1 + i,
        amount: dividedPrice,
        text: `[${i + 2}/${itemDivisions}] - ${currentName}`,
        itemName: `[${i + 2}/${itemDivisions}] - ${currentName}`,
      });
      newItems.splice(localItemsIndexEditing + 1 + i, 0, {
        ...currentDocData,
        index: newItems[localItemsIndexEditing].index + 1 + i,
        data: dividedPrice,
        text: `[${i + 2}/${itemDivisions}] - ${currentName}`,
        itemName: `[${i + 2}/${itemDivisions}] - ${currentName}`,
      });
    }

    setIgnoreTotalToCheck(true); // ignore if we edit an amount
    setItems(newItems);
    setShowDivideItemModal(false);

    // when we edit an item, it should RESET the Selection sessions
    setReviewAmountsEdited(true);
  };

  const handleSubmitTotalEditModal = () => {
    let parsed = parseFloat(typedTotal);
    if (parsed) {
      mixpanel.track("Total Corrected");
      if (
        window.confirm(
          "Please confirm that " +
            typedTotal +
            " is the correct Total amount shown on your bill"
        )
      ) {
        setTotalToCheckAgainst(parsed);
        setSubtotalPlusTax(parsed);

        const docRef = doc(db, "sessions", receiptID);
        updateDoc(docRef, {
          ocrTotal: parsed,
        });
        setShowTotalEditModal(false);
      }
    } else {
      alert("Enter the dollar amount of the total");
    }
  };

  const handleSubmitSubtotalEditModal = () => {
    let parsed = parseFloat(typedSubtotal);
    if (parsed === 0 || parsed) {
      mixpanel.track("Subtotal Edited");
      setSubtotal(parsed);
      setSubtotalPlusTax(parsed + taxAmount);
      const docRef = doc(db, "sessions", receiptID);
      updateDoc(docRef, {
        estimatedTaxRate: taxAmount / parsed,
      });
      setShowSubtotalEditModal(false);
    } else {
      alert("Enter the dollar amount of the subtotal");
    }
  };

  const setPartyReadyDb = async (isPartyReady) => {
    const docRef = doc(db, "sessions", receiptID);
    await updateDoc(docRef, { partyReady: isPartyReady });
  };

  return (
    <div>
      <div className="absolute top-0 left-0">
        <button
          className="top-button mt-4 ml-4 border-0"
          onClick={() => {
            if (confirmationPage === "Review") {
              goBack();
            } else if (confirmationPage === "SelectionEmbed") {
              setConfirmationPage("QRCodeShareEmbed");
            } else if (confirmationPage === "QRCodeShareEmbed") {
              setConfirmationPage("Review");
              setReviewAmountsEdited(false);
            } else if (confirmationPage === "ReadyCheckEmbed") {
              setConfirmationPage("SelectionEmbed");
            } else if (confirmationPage === "FinalWaitingEmbed") {
              setPartyReady(false);
              setPartyReadyDb(false);
              setConfirmationPage("ReadyCheckEmbed");
            }
          }}
        >
          <svg
            width="13"
            height="21"
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.58 0.460035C11.8557 0.746552 12.0063 1.13079 11.9988 1.52831C11.9913 1.92584 11.8263 2.30412 11.54 2.58003L3.66401 10L11.54 17.42C11.6887 17.5549 11.8088 17.7183 11.8932 17.9004C11.9776 18.0825 12.0246 18.2798 12.0314 18.4804C12.0382 18.681 12.0046 18.881 11.9327 19.0684C11.8608 19.2558 11.752 19.4269 11.6127 19.5715C11.4735 19.7161 11.3066 19.8313 11.122 19.9102C10.9375 19.9892 10.7389 20.0303 10.5382 20.031C10.3374 20.0318 10.1386 19.9923 9.95336 19.9149C9.76816 19.8374 9.6004 19.7235 9.46001 19.58L0.460015 11.08C0.314842 10.9401 0.199369 10.7724 0.120498 10.5868C0.0416265 10.4012 0.000976562 10.2017 0.000976562 10C0.000976562 9.7984 0.0416265 9.59884 0.120498 9.41328C0.199369 9.22772 0.314842 9.05996 0.460015 8.92003L9.46001 0.420035C9.74653 0.144374 10.1308 -0.00625661 10.5283 0.00124382C10.9258 0.00874426 11.3041 0.173762 11.58 0.460035Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {confirmationPage === "Review" && (
        <div className="flex flex-col bg-background-gradient bg-cover min-h-screen">
          <Header />
          <div>
            <div className="text-white text-xl mb-4">
              Double check items and choose tip
            </div>
            <Modal
              isOpen={showTotalEditModal}
              contentLabel="Custom Total Entry"
              shouldCloseOnOverlayClick={true}
              className="bg-black m-8 py-8 px-4 z-20"
            >
              <div className="flex flex-col items-center">
                <div className="flex flex-row items-center">
                  <span className="text-5xl text-white font-display mr-2">
                    Total: $
                  </span>
                  <input
                    className="font-display text-5xl text-center w-36"
                    placeholder={(calculatedSubtotal + taxAmount).toFixed(2)}
                    value={typedTotal}
                    onChange={(evt) => {
                      setTypedTotal(evt.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center mt-8">
                <button
                  className="text-white border border-white p-4"
                  onClick={handleSubmitTotalEditModal}
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={showTaxEditModal}
              contentLabel="Custom Tax Entry"
              shouldCloseOnOverlayClick={true}
              className="bg-black m-8 py-8 px-4 z-20"
            >
              <div className="flex flex-col items-center">
                <div className="flex flex-row items-center">
                  <span className="text-5xl text-white font-display mr-2">
                    Tax: $
                  </span>
                  <input
                    className="font-display text-5xl text-center w-36"
                    placeholder="25"
                    value={typedTax}
                    onChange={(evt) => {
                      setTypedTax(evt.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center mt-8">
                <button
                  className="text-white border border-white p-4"
                  onClick={handleSubmitTaxEditModal}
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={showSubtotalEditModal}
              contentLabel="Custom Subtotal Entry"
              shouldCloseOnOverlayClick={true}
              className="bg-black m-8 py-8 px-4 z-20"
            >
              <div className="flex flex-col items-center">
                <div className="flex flex-row items-center">
                  <span className="text-3xl text-white font-display mr-2">
                    Subtotal: $
                  </span>
                  <input
                    className="font-display text-3xl text-center w-32"
                    placeholder={subtotal.toFixed()}
                    value={typedSubtotal}
                    onChange={(evt) => {
                      setTypedSubtotal(evt.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center mt-8">
                <button
                  className="text-white border border-white p-4"
                  onClick={handleSubmitSubtotalEditModal}
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={showEditItemModal}
              contentLabel="Edit Item"
              shouldCloseOnOverlayClick={true}
              className="bg-black m-8 py-8 px-4 z-20"
            >
              <div className="flex flex-col items-start">
                <p className="text-3xl text-white font-display">Item Name</p>
                <input
                  className="font-display text-2xl w-64"
                  placeholder={items[localItemsIndexEditing]?.name ?? ""}
                  value={typedEditedItemName}
                  onChange={(evt) => {
                    setTypedEditedItemName(evt.target.value);
                  }}
                />
                <p className="text-3xl text-white font-display mt-4">Price</p>
                <div className="flex flex-row items-center">
                  <p className="text-white text-2xl mr-2">$</p>
                  <input
                    className="font-display text-2xl w-32"
                    placeholder={items[localItemsIndexEditing]?.data ?? ""}
                    value={typedEditedItemPrice}
                    onChange={(evt) => {
                      setTypedEditedItemPrice(evt.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center mt-8">
                <button
                  className="text-white border border-white p-4"
                  onClick={handleSubmitEditedItem}
                >
                  OK
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={showDivideItemModal}
              contentLabel="Divide Item"
              shouldCloseOnOverlayClick={true}
              className="bg-black m-8 py-8 px-4 z-20"
            >
              <div
                className="float-right text-white"
                onClick={() => {
                  setShowDivideItemModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-3xl text-white font-display">
                  Divide item by
                </p>
                <input
                  className="font-display text-3xl w-16 text-center p-4 my-4"
                  placeholder={items[localItemsIndexEditing]?.name ?? ""}
                  value={typedItemDivisions}
                  type={"number"}
                  onChange={(evt) => {
                    setTypedItemDivisions(evt.target.value);
                  }}
                />
                <button
                  className="text-white border border-white p-4 w-16 mt-1"
                  onClick={handleSubmitDividedItem}
                >
                  OK
                </button>
              </div>
            </Modal>

            <div
              className="w-full"
              style={{
                padding: "4px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "start",
              }}
            >
              <div className="font-body font-bold text-2xl text-white">
                Subtotal
              </div>
              <div className="flex flex-row items-center">
                <button onClick={handleEditSubtotal}>
                  <TbEdit className="text-white mr-2" />
                </button>
                <div className="font-body font-bold text-2xl text-white">
                  {"$" + subtotal.toFixed(2)}
                </div>
                {/* <button className="text-white ml-2" onClick={() => {
              alert(`Your bill originally reported a total of: $${shownTotal}`);
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
            </button> */}
              </div>
            </div>
            <div
              className="w-full"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "start",
              }}
            >
              <div className="font-body font-bold text-2xl text-white">Tax</div>
              <div className="flex flex-row items-center">
                <button onClick={handleEditTax}>
                  <TbEdit className="text-white mr-2" />
                </button>
                <div className="font-body font-bold text-2xl text-white">
                  {"$" + taxAmount.toFixed(2)}
                </div>
              </div>
            </div>
            <div
              className="w-full"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "start",
              }}
            >
              <div className="font-body font-bold text-2xl text-white">
                Total (pre-tip)
              </div>
              <div className="flex flex-row">
                <div className="font-body font-bold text-2xl text-white">
                  {"$" + subtotalPlusTax.toFixed(2)}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100vw",
                height: "4px",
                backgroundColor: "#F5F5F5",
              }}
            />
            <div
              className="w-full mb-4"
              style={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "start",
              }}
            >
              <div className="font-body font-bold text-2xl text-white">
                Total
              </div>
              <div className="flex flex-row">
                <div className="font-body font-bold text-2xl text-white">
                  {"$" +
                    (
                      subtotalPlusTax +
                      (tipPercent / 100.0) * subtotalPlusTax
                    ).toFixed(2)}
                </div>
              </div>
            </div>
            <div style={{ padding: "12px 0px" }}>
              {items.map(
                (element, index) =>
                  element && (
                    <div
                      key={"elementDiv" + element.text + element.index + index}
                      className="bg-white/20 flex flex-row items-center text-white"
                      style={{
                        alignContent: "center",
                        margin: "0px 12px 12px 12px",
                      }}
                    >
                      <div style={{ flexGrow: 4 }}>
                        <Item
                          key={element.index}
                          name={element.text}
                          price={element.data}
                          dense={false}
                        />
                      </div>
                      <SquareButton
                        onClick={() => {
                          setLocalItemsIndexEditing(index);
                          const name = element.itemName;
                          let matches = name.match(/\d+/g);

                          // Display output if number extracted
                          if (matches) {
                            setTypedItemDivisions(matches[0]);
                          }
                          setShowDivideItemModal(true);
                          // let name = element.text;
                          // const price = element.data;
                          // if (name.indexOf("$") > -1) {
                          //   name = name.substring(0, name.indexOf("$"));
                          // }
                          // if (name.indexOf(price) > -1) {
                          //   name = name.substring(0, name.indexOf(price));
                          // }
                          // setTypedEditedItemName(name);
                          // setTypedEditedItemPrice(price);
                          // setShowEditItemModal(true);
                        }}
                        icon={<TbDivide />}
                      />
                      <SquareButton
                        onClick={() => {
                          setLocalItemsIndexEditing(index);
                          let name = element.itemName;
                          const price = element.data;
                          setTypedEditedItemName(name);
                          setTypedEditedItemPrice(price);
                          setShowEditItemModal(true);
                        }}
                        icon={<TbEdit />}
                      />
                      <SquareButton
                        onClick={() => {
                          if (window.confirm("Delete item?")) {
                            items.splice(index, 1);
                            var newItems = [...items];
                            setItems(newItems);
                            deleteItem(
                              receiptID,
                              calculatedSubtotal,
                              element.itemName,
                              element.data,
                              element.index
                            );
                            setIgnoreTotalToCheck(true);
                            setReviewAmountsEdited(true);
                          }
                        }}
                        icon={<HiTrash />}
                      />
                    </div>
                  )
              )}
            </div>
            <div style={{ height: "212px" }}></div>
          </div>
        </div>
      )}
      {confirmationPage === "SelectionEmbed" && (
        <div>
          <Selection
            receiptID={receiptID}
            confirmationEmbed={true}
            savePayerSession={setMyPayerSessionID}
          />
        </div>
      )}
      {confirmationPage === "QRCodeShareEmbed" && (
        <div className="flex flex-col bg-background-gradient bg-cover min-h-screen">
          <Header />
          <QRCodeShare
            payerSessions={payerSessions}
            qrCodeURL={qrCodeURL}
            partyMemberCount={partyMemberCount}
            myPayerSessionID={myPayerSessionID}
          />
        </div>
      )}
      {confirmationPage === "ReadyCheckEmbed" && (
        <div className="flex flex-col bg-background-gradient bg-cover min-h-screen">
          <Header />
          <ReadyCheck
            payerSessions={payerSessions}
            subtotalPlusTax={subtotalPlusTax}
            tipPercent={tipPercent}
            partyMemberCount={partyMemberCount}
            myPayerSessionID={myPayerSessionID}
            otherPayerTotal={otherPayerTotal}
          />
        </div>
      )}
      {confirmationPage === "FinalWaitingEmbed" && (
        <div className="flex flex-col bg-background-gradient bg-cover min-h-screen">
          <Header />
          <FinalWaiting
            payerSessions={payerSessions}
            subtotalPlusTax={subtotalPlusTax}
            tipPercent={tipPercent}
            partyMemberCount={partyMemberCount}
            myPayerSessionID={myPayerSessionID}
            partyReady={partyReady}
          />
        </div>
      )}
      {/* START FIXED ACTIONS CONTAINER  */}
      <div className="fixed-actions-container">
        {/* <div className="flex-space-between">
              <p className="font-body text-xl"> Party size? </p>
              <PartyCounter
                changeCount={(val) =>
                  setPartyMemberCount(partyMemberCount + val)
                }
                count={partyMemberCount}
              />
            </div> */}
        {confirmationPage === "Review" && (
          <TipSelector
            tipPercent={tipPercent}
            subtotalPlusTax={subtotalPlusTax}
            changeTip={(val, isDollars = false) => {
              if (isDollars) {
                val = (val / subtotalPlusTax) * 100.0;
              }
              setTipPercent(val);
            }}
          />
        )}
        {/* <div className="flex flex-row border-t border-white">
              <div className="p-4 w-48 flex flex-row items-center">
                <span>@</span>
                <input
                  className="bg-transparent w-full"
                  placeholder="enter_your_venmo"
                  value={ownerVenmoHandle}
                  onChange={(evt) => setOwnerVenmoHandle(evt.target.value)}
                ></input>
              </div>
              <button
                className="grow h-16 bg-[#070A2C] text-white text-xl font-body font-semibold"
                onClick={() => {
                  // With promises
                  QRCode.toDataURL(window.location.href)
                    .then((url) => {
                      mixpanel.track("Show QR Code");
                      setQRCodeURL(url);
                      setShowingQRCode(true);
                    })
                    .catch((err) => {
                      alert(
                        "Sorry, we had some trouble generating the QR code. Please try again."
                      );
                      console.error(err);
                    });
                }}
              >
                Show QR Code 🔳
              </button>
            </div> */}
        {confirmationPage === "Review" && (
          <div
            className="bg-black text-white m-4 p-4 text-xl font-bold"
            onClick={() => {
              // delete sessions
              if (reviewAmountsEdited) {
                // TODO: do we need to delete the payer sessions now that it's saved on items?
                // deletePayerSessions();
              }
              QRCode.toDataURL(window.location.href)
                .then((url) => {
                  mixpanel.track("Show QR Code");
                  setQRCodeURL(url);
                  setConfirmationPage("QRCodeShareEmbed");
                })
                .catch((err) => {
                  alert(
                    "Sorry, we had some trouble generating the QR code. Please try again."
                  );
                  console.error(err);
                });
            }}
          >
            Everything looks good (1/4) ✅
          </div>
        )}
        {confirmationPage === "QRCodeShareEmbed" && (
          <div
            className="bg-black text-white m-4 p-4 text-xl font-bold"
            onClick={() => {
              setConfirmationPage("SelectionEmbed");
            }}
          >
            Everyone scanned (2/4) ✅
          </div>
        )}
        {confirmationPage === "SelectionEmbed" && (
          <div
            className="bg-black text-white m-4 p-4 text-xl font-bold"
            onClick={() => {
              setConfirmationPage("ReadyCheckEmbed");
            }}
          >
            Everyone added their items (3/4) ✅
          </div>
        )}

        {confirmationPage === "ReadyCheckEmbed" && (
          <div
            className="bg-black text-white m-4 p-4 text-xl font-bold"
            onClick={() => {
              if (
                window.confirm(
                  `You'll be paid $${otherPayerTotal.toFixed(
                    2
                  )}, leaving you covering $${(
                    subtotalPlusTax +
                    (tipPercent / 100.0) * subtotalPlusTax -
                    otherPayerTotal
                  ).toFixed(
                    2
                  )}. If that looks right, you can tell everyone to Venmo you now.`
                )
              ) {
                setPartyReadyDb(true);
                setConfirmationPage("FinalWaitingEmbed");
              }
            }}
          >
            Everyone selected their items (4/4)
            <br />
            Start Venmoing me ✅
          </div>
        )}
      </div>
      {/* END FIXED ACTIONS CONTAINER  */}
    </div>
  );
}

// const deletePayerSessions = async () => {
//   const q = query(
//     collection(db, "sessions", receiptID, "payerSessions"),
//     where("selectedItemCount", ">", 0)
//   );
//   const querySnapshot = await getDocs(q);
//   const docIDs = [];
//   querySnapshot.forEach((doc) => {
//     docIDs.push(doc.id);
//   });
//   docIDs.forEach((docID) => {
//     const docRef = doc(db, "sessions", receiptID, "payerSessions", docID);
//     deleteDoc(docRef).then(() => {
//       console.log("deleted successfully");
//     });
//   });
// };
